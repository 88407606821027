import './App.css';
import advanceLogo from './img/AE logo_no background - 1.png'
import advanceThLogo from './img/Logo Advance no BG - 2.PNG'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div style={{}}>
          <img src={advanceLogo} style={{ width: 200}} />
          <img src={advanceThLogo} style={{   width: 360 }} />
        </div>
        <h4 style={{ color: "red", paddingTop: 20 }}>
          www.advance-e.net is under construction
        </h4>
      </header>
      <body>
        <h4 style={{ color: "#177BF3" }}>
          Contact info
        </h4>
        <div>
          <h5 >
            [Cambodia] office No. 153C, St. 36 Sony,
            Sangkat Teouk Thla​​ Khan Sensok, Phnom Penh,
            Cambodia. <br />Tel: 855(97)770 7770, 855 (77) 641 028
            Email : yanisa@advance-e.net
          </h5>

        </div>
        <div />
        <h5>
          [Thailand] 61/70 Rama 9 Road, Huai Khwang,
          Huai Khwang, Bangkok 10310, <br />
          Tel : +662 643 0633 , Fax : +662 643 0634
          Email : yanisa@advance-e.net
        </h5>
      </body>

    </div>
  );
}

export default App;
